import React from 'react'

import {
  CheckoutPaymentFrequency,
  PROPOSAL_COOKIE,
  makeStorageProvider,
} from '@azos/core'
import { DiscountByProposal } from '@domain/models'
import { useRouter } from 'next/router'
import nookies, { setCookie } from 'nookies'

const PAYMENT_FREQUENCY_COOKIE = '@azos/payment-frequency'

type CheckoutContextData = {
  expiresAt?: string
  setExpiresAt: (expires: string) => void
  nextDueDate?: string
  setNextDueDate: (expires: string) => void
  setPaymentFrequency: (paymentFrequency: CheckoutPaymentFrequency) => void
  paymentFrequency: CheckoutPaymentFrequency
  discount?: DiscountByProposal | null
  setDiscount: (discount?: DiscountByProposal | null) => void
  destroyCookie: () => void
}

type PaymentFrequencyCookieProps = {
  paymentFrequency: CheckoutPaymentFrequency
  discount?: DiscountByProposal | null
  proposalId: string
}

type CheckoutProviderProps = {}

const storageProvider = makeStorageProvider()

const CheckoutContext = React.createContext({} as CheckoutContextData)

const CheckoutProvider: React.FC<CheckoutProviderProps> = ({ children }) => {
  const router = useRouter()

  const { proposalId } = storageProvider.getJSON<{
    proposalId: string
    quotationId: string
  }>(PROPOSAL_COOKIE)

  const [expiresAt, setExpiresAt] = React.useState<string | undefined>()
  const [nextDueDate, setNextDueDate] = React.useState<string>('')

  const [paymentFrequency, setPaymentFrequency] = React.useState(() => {
    const cookies = nookies.get(null)
    const data = cookies?.[PAYMENT_FREQUENCY_COOKIE]
    const json = data ? JSON.parse(data) : undefined

    return json?.paymentFrequency || CheckoutPaymentFrequency.ANNUAL
  })

  const [discount, setDiscount] = React.useState<
    DiscountByProposal | undefined | null
  >(null)

  const destroyCookie = React.useCallback(() => {
    nookies.destroy(null, PAYMENT_FREQUENCY_COOKIE)
  }, [])

  React.useEffect(() => {
    const isPaymentRoute = router.pathname === '/simular-novo-seguro/pagamento'

    if (proposalId && isPaymentRoute) {
      const paymentCookie: PaymentFrequencyCookieProps = {
        proposalId,
        paymentFrequency,
        discount,
      }

      setCookie(null, PAYMENT_FREQUENCY_COOKIE, JSON.stringify(paymentCookie), {
        maxAge: 3600,
        path: '/',
        sameSite: true,
      })
    }
  }, [discount, paymentFrequency, proposalId, router.pathname])

  React.useEffect(() => {
    if (proposalId) {
      const cookies = nookies.get(null)
      const paymentCookie = cookies[PAYMENT_FREQUENCY_COOKIE]
      const paymentFrequencyCookie = paymentCookie
        ? (JSON.parse(paymentCookie) as PaymentFrequencyCookieProps | null)
        : null

      if (
        paymentFrequencyCookie &&
        paymentFrequencyCookie.proposalId === proposalId
      ) {
        setPaymentFrequency(paymentFrequencyCookie.paymentFrequency)
      }

      if (
        paymentFrequencyCookie?.discount &&
        paymentFrequencyCookie.proposalId === proposalId
      ) {
        setDiscount(paymentFrequencyCookie.discount)
      }
    }
  }, [proposalId])

  const contextData: CheckoutContextData = {
    expiresAt,
    setExpiresAt,
    nextDueDate,
    setNextDueDate,
    paymentFrequency,
    setPaymentFrequency,
    discount,
    setDiscount,
    destroyCookie,
  }

  return (
    <CheckoutContext.Provider value={contextData}>
      {children}
    </CheckoutContext.Provider>
  )
}

export { CheckoutProvider, CheckoutContext }
